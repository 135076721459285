import React, { Fragment, useEffect, useState } from 'react';
import styles from './Subscription.module.scss';
import AuthLayout from '../../Layouts/AuthLayout/AuthLayout';
import SettingsHeader from '../../Components/SettingsHeader/SettingsHeader';
import { Button, Image, Input, Row, Col, Tooltip, App } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { updateProductFeatures } from '../../Redux/Features/updateProductFeaturesSlice';
import { getProducts } from '../../Redux/Features/getProductsSlice';
import validator from 'validator';
import { Error } from '../../Components/Commons/Message/Message';
import { extractValues, textFormator } from '../../utils/helper';

const Subscription = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { notification } = App.useApp();
  const { products } = useSelector((state) => state?.getProducts);
  const { isLoading } = useSelector((state) => state?.updateProductFeatures);
  const [formData, setFormData] = useState([]);
  const [productId, setProductId] = useState('');

  useEffect(() => {
    dispatch(getProducts());
  }, []);

  useEffect(() => {
    if (products) {
      const copyProducts = products?.length > 0 ? JSON.parse(JSON.stringify(products)) : [];
      setFormData(copyProducts);
    }
  }, [products]);

  const setForm = (pIndex, feature, value) => {
    let productData = [...formData];
    productData[pIndex].features[feature] = value;
    setFormData(productData);
  };

  const setProductState = (productIndex, state) => {
    let productData = [...formData];
    productData[productIndex].is_active = state;
    setFormData(productData);
  };

  const updateSubscriptionConfiguration = (product) => {
    let isErr = false;

    for (let key in product.features) {
      if (validator?.isEmpty(product.features[key]?.toString())) {
        isErr = true;
        Error(`${textFormator(key)} is required`);
      }
    }

    if (isErr) {
      isErr = false;
    } else {
      let details = {
        product,
        notification,
        onSuccess: () => {
          setProductId('');
        },
      };
      setProductId(product?._id);
      dispatch(updateProductFeatures(details));
    }
  };

  return (
    <AuthLayout>
      <SettingsHeader
        content={
          <div className={styles.profile_setting_container}>
            <div className={styles.profile_details}>
              <h5>Admin Dashboard</h5>
              <h2>Subscription Configuration</h2>
            </div>
          </div>
        }
      />
      {formData?.map((product, pIndex) => (
        <div key={pIndex} className={styles.subscription_container}>
          <div className={styles.subscription_header}>
            <div className={styles.title}>
              <h6>{product?.product_name}</h6>
            </div>
            <div className={styles.sub_title}>
              <h6>Stripe Product Id: {product?.product_id}</h6>
              <h6>Stripe Price Id: {product?.price_id}</h6>
            </div>
          </div>
          <div className={styles.product_status}>
            <Input
              size="small"
              type="checkbox"
              checked={product?.is_active}
              onChange={(e) => {
                setProductState(pIndex, Boolean(e?.target?.checked));
              }}
            />
            <p>Active</p>
          </div>
          <div className={styles.configuration_container}>
            <div className={styles.rules_container}>
              <Row>
                {Object?.keys(product?.features)?.map((feature, fIndex) => (
                  <Fragment key={fIndex}>
                    <Col xs={24} sm={12} md={8} lg={6}>
                      <div className={styles.rule}>
                        {typeof product.features[feature] === 'boolean' ? (
                          <Input
                            size="small"
                            type="checkbox"
                            checked={product?.features[feature]}
                            onChange={(e) => {
                              setForm(pIndex, feature, Boolean(e?.target?.checked));
                            }}
                          />
                        ) : (
                          <Input
                            style={{ width: '60px' }}
                            size="small"
                            value={Number(extractValues(String(product?.features[feature]))?.number)}
                            suffix={extractValues(String(product?.features[feature]))?.letter}
                            onChange={(e) => {
                              const value = e?.target?.value;
                              const letter = extractValues(product?.features[feature])?.letter;
                              const reg = /^-?\d*(\.\d*)?$/;

                              if (reg?.test(value) || value === '') {
                                if (letter) {
                                  setForm(pIndex, feature, String(`${value ? value : '0'}${letter}`));
                                } else {
                                  setForm(pIndex, feature, String(value));
                                }
                              }
                            }}
                          />
                        )}

                        <p>{textFormator(feature)} </p>
                      </div>
                    </Col>
                    {(fIndex + 1) % 4 == 0 && <div className={styles.separator}></div>}
                  </Fragment>
                ))}
              </Row>
            </div>
          </div>
          <div className={styles.button_container}>
            <Button
              size="small"
              key={pIndex}
              type="primary"
              onClick={() => updateSubscriptionConfiguration(product)}
              loading={productId === product?._id && isLoading}
              disabled={isLoading && productId !== product?._id}
            >
              Update
            </Button>
          </div>
        </div>
      ))}
    </AuthLayout>
  );
};

export default Subscription;
