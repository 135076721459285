export const setCookie = (key, data) => {
  localStorage.setItem(key, JSON.stringify(data));
};

export const getCookie = (key) => {
  return localStorage.getItem(key) ? JSON.parse(localStorage.getItem(key)) : '';
};

export const removeCookie = (key) => {
  return localStorage.removeItem(key);
};

export const textFormator = (originalString) => {
  if (!originalString) {
    return '';
  }
  const stringWithSpaces = originalString?.replace(/([a-z])([A-Z])/g, '$1 $2');
  const formattedString = stringWithSpaces?.charAt(0)?.toUpperCase() + stringWithSpaces?.slice(1)?.toLowerCase();
  return formattedString;
};

export function extractValues(timeString) {
  const match = /^(\d*)([a-zA-Z]*)$/.exec(timeString);
  if (match) {
    const number = match[1] === '' ? null : parseInt(match[1], 10);
    const letter = match[2] === '' ? null : match[2];
    return { number, letter };
  } else {
    return { number: '', letter: '' };
  }
}
