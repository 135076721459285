import React from 'react';
import styles from './Logs.module.scss';
import AuthLayout from '../../Layouts/AuthLayout/AuthLayout';
import SettingsHeader from '../../Components/SettingsHeader/SettingsHeader';
import { useNavigate } from 'react-router-dom';
import { DatePicker, Input } from 'antd';
import dayjs from 'dayjs';

const Logs = () => {
  const navigate = useNavigate();
  const dateFormat = 'YYYY/MM/DD';
  const tabList = [
    {
      id: 0,
      title: 'Subscription Configuration',
      active: false,
      keys: ['/subscription'],
      onClick: () => navigate('/subscription'),
    },
    {
      id: 1,
      title: 'User Configuration',
      active: false,
      keys: ['/user'],
      onClick: () => navigate('/user'),
    },
    {
      id: 2,
      title: 'Logs',
      active: false,
      keys: ['/logs'],
      onClick: () => navigate('/logs'),
    },
  ];
  return (
    <AuthLayout>
      <SettingsHeader
        tabList={tabList}
        content={
          <div className={styles.profile_setting_container}>
            <div className={styles.profile_details}>
              <h5>Admin Dashboard</h5>
              <h2>Logs</h2>
            </div>
          </div>
        }
      />
      <div className={styles.main_logs_container}>
        <div className={styles.header_container}>
          <div className={styles.search_container}>
            <Input
              placeholder="Type to search"
              size="large"
              prefix={<img src={'/Assets/search_2.svg'} alt={'Search icon'} />}
              style={{ borderRadius: '6px', borderColor: '#DDE2E4' }}
            />
          </div>
          <div className={styles.date_container}>
            <div className={styles.from}>
              <p>From</p>
              <DatePicker
                defaultValue={dayjs('2015/01/01', dateFormat)}
                format={dateFormat}
                suffixIcon={<img src={'/Assets/arrow_down_1.svg'} alt={'Arrow down icon'} />}
                style={{
                  padding: '8px 16px 8px 12px',
                  borderColor: 'var(--light-gray-light-gray-2, #DDE2E4)',
                }}
              />
            </div>
            <div className={styles.to}>
              <p>To</p>
              <DatePicker
                defaultValue={dayjs('2015/01/01', dateFormat)}
                format={dateFormat}
                suffixIcon={<img src={'/Assets/arrow_down_1.svg'} alt={'Arrow down icon'} />}
                style={{
                  padding: '8px 16px 8px 12px',
                  borderColor: 'var(--light-gray-light-gray-2, #DDE2E4)',
                }}
              />
            </div>
          </div>
        </div>
        <div className={styles.logs_container}>
          <table>
            <thead>
              <tr>
                <th className={styles.username}>
                  <p>User name</p>
                </th>
                <th className={styles.id}>
                  <p>ID</p>
                </th>
                <th className={styles.status}>
                  <p>Status</p>
                </th>
                <th className={styles.date}>
                  <p>Date</p>
                </th>
                <th className={styles.time}>
                  <p>Time</p>
                </th>
              </tr>
            </thead>
            <tbody>
              {Array(6)
                ?.fill()
                ?.map((item, i) => (
                  <tr key={i}>
                    <td className={styles.username}>
                      <p>Niro Diboki</p>
                    </td>
                    <td className={styles.id}>
                      <p>id: 123</p>
                    </td>
                    <td className={styles.status}>
                      <p>Updated LinkedIn credentials</p>
                    </td>
                    <td className={styles.date}>
                      <p>01/01/2023</p>
                    </td>
                    <td className={styles.time}>
                      <p>11:12:13</p>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </AuthLayout>
  );
};

export default Logs;
