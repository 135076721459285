import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_URL } from '../../Config';
import { removeCookie } from '../../utils/helper';
import setAuthToken from '../../utils/setAuthToken';
import { notification } from 'antd';

const initialState = {
  isLoading: false,
  isError: null,
  errors: [],
  isSuccess: false,
  message: '',
  isAuthenticated: false,
  token: '',
  user: {},
};

export const getAdmin = createAsyncThunk('users/get-admin', async (token, { rejectWithValue, dispatch }) => {
  try {
    setAuthToken(token);
    const response = await axios.get(`${BASE_URL}/api/admin/`);
    return { ...response?.data, token };
  } catch (error) {
    // notification.error({
    //   message: 'Error',
    //   description: error?.response?.data?.errors[0]?.message || 'Network error',
    // });
    return rejectWithValue(error?.response?.data);
  }
});

const adminSlice = createSlice({
  name: 'Admin',
  initialState: initialState,
  reducers: {
    userLogout: (state, action) => {
      removeCookie('token');
      state.user = {};
      state.token = '';
      state.isAuthenticated = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAdmin.pending, (state) => {
      state.isLoading = true;
      state.isAuthenticated = false;
    });
    builder.addCase(getAdmin.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isError = false;
      state.errors = [];
      state.isAuthenticated = true;
      state.isSuccess = action?.payload?.success;
      state.message = action?.payload?.message;
      state.user = action?.payload?.user;
      state.token = action?.payload?.token;
    });
    builder.addCase(getAdmin.rejected, (state, action) => {
      state.isLoading = false;
      state.errors = action.payload?.errors || [];
      state.isError = true;
      state.isAuthenticated = false;
    });
  },
});

export const { userLogout } = adminSlice?.actions;

export default adminSlice?.reducer;
