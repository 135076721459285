import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_URL } from '../../Config';

const initialState = {
  isLoading: false,
  isError: null,
  errors: [],
  isSuccess: false,
  message: '',
  products: [],
};

export const updateProductFeatures = createAsyncThunk(
  'Product/update-features',
  async ({ product, onSuccess, notification }, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${BASE_URL}/api/product/update-features/${product?._id}`, product);
      response?.data?.message &&
        notification.success({
          message: 'Success',
          description: response?.data?.message,
        });
      onSuccess && onSuccess();
      return response?.data;
    } catch (error) {
      notification.error({
        message: 'Error',
        description: error?.response?.data?.errors[0]?.message || 'Network error',
      });
      return rejectWithValue(error?.response?.data);
    }
  }
);

const updateProductFeaturesSlice = createSlice({
  name: 'Update Product Features',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(updateProductFeatures.pending, (state) => {
      state.isLoading = true;
      state.isSuccess = false;
    });
    builder.addCase(updateProductFeatures.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isError = false;
      state.errors = [];
      state.isSuccess = action?.payload?.success;
      state.message = action?.payload?.message;
      state.products = action?.payload?.products;
    });
    builder.addCase(updateProductFeatures.rejected, (state, action) => {
      state.isLoading = false;
      state.errors = action.payload?.errors || [];
      state.products = [];
      state.isError = true;
    });
  },
});

export default updateProductFeaturesSlice?.reducer;
