import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_URL } from '../../Config';

const initialState = {
  isLoading: false,
  isError: null,
  errors: [],
  isSuccess: false,
  message: '',
};

export const addProxy = createAsyncThunk(
  'Proxy/add-proxy',
  async ({ data, onSuccess, notification }, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${BASE_URL}/api/proxy/add`, data);
      response?.data?.message && notification?.success({ message: 'Success', description: response?.data?.message });
      onSuccess && onSuccess();
      return response?.data;
    } catch (error) {
      notification?.error({
        message: 'Error',
        description: error?.response?.data?.errors[0]?.message || 'Network error',
      });
      return rejectWithValue(error?.response?.data);
    }
  }
);

const addProxySlice = createSlice({
  name: 'Add Proxy',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(addProxy.pending, (state) => {
      state.isLoading = true;
      state.isSuccess = false;
    });
    builder.addCase(addProxy.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isError = false;
      state.errors = [];
      state.isSuccess = action?.payload?.success;
      state.message = action?.payload?.message;
    });
    builder.addCase(addProxy.rejected, (state, action) => {
      state.isLoading = false;
      state.errors = action.payload?.errors || [];
      state.products = [];
      state.isError = true;
    });
  },
});

export default addProxySlice?.reducer;
