import { configureStore } from '@reduxjs/toolkit';
import adminLoginSlice from './Features/adminLoginSlice';
import adminSlice from './Features/adminSlice';
import getProductsSlice from './Features/getProductsSlice';
import updateProductFeaturesSlice from './Features/updateProductFeaturesSlice';
import getProxiesSlice from './Features/getProxiesSlice';
import addProxySlice from './Features/addProxySlice';

export const store = configureStore({
  reducer: {
    admin: adminSlice,
    adminLogin: adminLoginSlice,
    getProducts: getProductsSlice,
    updateProductFeatures: updateProductFeaturesSlice,
    getProxies: getProxiesSlice,
    addProxy: addProxySlice,
  },
});
