import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_URL } from '../../Config';
import { notification } from 'antd';

const initialState = {
  isLoading: false,
  isError: null,
  errors: [],
  isSuccess: false,
  message: '',
  proxies: [],
};

export const getProxies = createAsyncThunk('Proxy/get-proxies', async (data, { rejectWithValue }) => {
  try {
    const response = await axios.get(`${BASE_URL}/api/proxy`);
    return response?.data;
  } catch (error) {
    return rejectWithValue(error?.response?.data);
  }
});

const getProxiesSlice = createSlice({
  name: 'Get Proxies',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getProxies.pending, (state) => {
      state.isLoading = true;
      state.isSuccess = false;
    });
    builder.addCase(getProxies.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isError = false;
      state.errors = [];
      state.isSuccess = action?.payload?.success;
      state.message = action?.payload?.message;
      state.proxies = action?.payload?.proxies;
    });
    builder.addCase(getProxies.rejected, (state, action) => {
      state.isLoading = false;
      state.errors = action.payload?.errors || [];
      state.products = [];
      state.isError = true;
    });
  },
});

export default getProxiesSlice?.reducer;
