import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import validator from 'validator';
import InputText from '../../Components/Commons/InputText/InputText';
import { Button, App } from 'antd';
import { adminLogin } from '../../Redux/Features/adminLoginSlice';
import styles from './Login.module.scss';
import UnAuthLayout from '../../Layouts/UnAuthLayout/UnAuthLayout';

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { notification } = App.useApp();
  const { isLoading } = useSelector((state) => state?.adminLogin);
  const [clientError, setClientError] = useState({});
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  const setFeild = (feildName, feildValue) => {
    setFormData({ ...formData, [feildName]: feildValue });
  };

  const handleSubmit = async () => {
    let isErr = false;
    let errors = {};

    if (!validator.isEmail(formData?.email)) {
      isErr = true;
      errors.email = 'Email is invalid';
    }
    if (!validator.isLength(formData?.password, { min: 6 })) {
      isErr = true;
      errors.password = 'Password should be 6 or more characters long';
    }
    if (validator.isEmpty(formData?.email)) {
      isErr = true;
      errors.email = 'Email is required';
    }
    if (validator.isEmpty(formData?.password)) {
      isErr = true;
      errors.password = 'Password is required';
    }

    if (isErr) {
      isErr = false;
      setClientError(errors);
    } else {
      setClientError({});
      const data = {
        data: {
          ...formData,
        },
        notification,
        onSuccess: () => {
          setFormData({
            email: '',
            password: '',
          });
        },
        navigate,
      };
      dispatch(adminLogin(data));
    }
  };

  return (
    <UnAuthLayout>
      <div className={styles.main_login_container}>
        <h1>Admin Log In</h1>
        <p></p>
        <div className={styles.login_form_container}>
          <div className={styles.input_container}>
            <InputText
              label={'Email address'}
              error={clientError?.email ? clientError?.email : false}
              value={formData?.email}
              size={'large'}
              onChange={(e) => setFeild('email', e?.target?.value)}
            />
            <InputText
              label={'Password'}
              size={'large'}
              type={'password'}
              error={clientError?.password ? clientError?.password : false}
              value={formData?.password}
              onChange={(e) => setFeild('password', e?.target?.value)}
            />
          </div>
          <div className={styles.button_container}>
            <Button onClick={handleSubmit} disabled={isLoading} loading={isLoading} type="primary" size="large" block>
              Log In
            </Button>
          </div>
        </div>
      </div>
    </UnAuthLayout>
  );
};

export default Login;
