import React, { useEffect, useState } from 'react';
import styles from './Proxies.module.scss';
import AuthLayout from '../../Layouts/AuthLayout/AuthLayout';
import SettingsHeader from '../../Components/SettingsHeader/SettingsHeader';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getProxies } from '../../Redux/Features/getProxiesSlice';
import { Button, Input, Modal, Space, App } from 'antd';
import validator from 'validator';
import { addProxy } from '../../Redux/Features/addProxySlice';
import InputText from '../../Components/Commons/InputText/InputText';

const Proxies = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { notification } = App.useApp();
  const { proxies } = useSelector((state) => state?.getProxies);
  const { isLoading: addProxyLoading } = useSelector((state) => state?.addProxy);
  const [addProxyModal, setAddProxyModal] = useState(false);
  const [clientErrors, setClientErrors] = useState({});
  const [proxyFormData, setProxyFormData] = useState({
    hostname: '',
    port: null,
    username: '',
    password: '',
  });

  useEffect(() => {
    dispatch(getProxies());
  }, []);

  const handleAddProxy = () => {
    let isErr = false;
    let errors = {};

    if (validator?.isEmpty(proxyFormData?.hostname?.toString())) {
      isErr = true;
      errors.hostname = 'Hostname is required';
    }

    if (proxyFormData?.port === null) {
      isErr = true;
      errors.port = 'Port is required';
    }

    if (isErr) {
      isErr = true;
      setClientErrors(errors);
    } else {
      setClientErrors({});
      dispatch(
        addProxy({
          data: proxyFormData,
          notification,
          onSuccess: () => {
            setProxyFormData({
              hostname: '',
              port: null,
              username: '',
              password: '',
            });
            dispatch(getProxies());
            setAddProxyModal(false);
          },
        })
      );
    }
  };

  return (
    <AuthLayout>
      <SettingsHeader
        content={
          <div className={styles.profile_setting_container}>
            <div className={styles.profile_details}>
              <h5>Admin Dashboard</h5>
              <h2>Proxies</h2>
            </div>
            <Button size="small" onClick={() => setAddProxyModal(true)} type="primary">
              Add proxy
            </Button>
          </div>
        }
      />
      <div className={styles.main_proxies_container}>
        <div className={styles.proxies_container}>
          <table>
            <thead>
              <tr>
                <th className={styles.id}>
                  <p>Hostname</p>
                </th>
                <th className={styles.id}>
                  <p>Port</p>
                </th>
                <th className={styles.username}>
                  <p>Usage</p>
                </th>
                <th className={styles.date}>
                  <p>Username</p>
                </th>
                <th className={styles.time}>
                  <p>Password</p>
                </th>
              </tr>
            </thead>
            <tbody>
              {proxies?.map((proxy, i) => (
                <tr key={i}>
                  <td className={styles.id}>
                    <p>{proxy?.hostname || 'Unknown'}</p>
                  </td>
                  <td className={styles.id}>
                    <p>{proxy?.port || 'Unknown'}</p>
                  </td>
                  <td className={styles.username}>
                    <p>{proxy?.linkedinprofiles?.length || '0'}</p>
                  </td>
                  <td className={styles.date}>
                    <p>{proxy?.username || 'Unknown'}</p>
                  </td>
                  <td className={styles.time}>
                    <p>{proxy?.password || 'Unknown'}</p>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <Modal
        open={addProxyModal}
        centered
        closable={false}
        okText={'Create'}
        onCancel={() => {
          setProxyFormData({
            hostname: '',
            port: null,
            username: '',
            password: '',
          });
          setClientErrors({});
          setAddProxyModal(false);
        }}
        okButtonProps={{ loading: addProxyLoading, disabled: addProxyLoading, size: 'small' }}
        cancelButtonProps={{ size: 'small' }}
        onOk={handleAddProxy}
      >
        <div className={styles.add_proxy_modal_container}>
          <Space
            direction="vertical"
            size={'middle'}
            style={{
              display: 'flex',
            }}
          >
            <div className={styles.input_container}>
              <InputText
                label={'Hostname'}
                size={'middle'}
                placeholder={'Required'}
                error={clientErrors?.hostname}
                value={proxyFormData?.hostname}
                onChange={(e) =>
                  setProxyFormData((data) => {
                    return { ...data, hostname: e?.target?.value };
                  })
                }
              />
            </div>
            <div className={styles.input_container}>
              <InputText
                label={'Port'}
                size={'middle'}
                placeholder={'Required'}
                error={clientErrors?.port}
                value={proxyFormData?.port}
                onChange={(e) => {
                  const reg = /^-?\d*(\.\d*)?$/;
                  if (reg?.test(e?.target?.value)) {
                    setProxyFormData((data) => {
                      return { ...data, port: Number(e?.target?.value) };
                    });
                  }
                }}
              />
            </div>
            <div className={styles.input_container}>
              <InputText
                label={'Username'}
                size={'middle'}
                placeholder={'Optional'}
                value={proxyFormData?.username}
                onChange={(e) =>
                  setProxyFormData((data) => {
                    return { ...data, username: e?.target?.value };
                  })
                }
              />
            </div>
            <div className={styles.input_container}>
              <InputText
                label={'Password'}
                size={'middle'}
                placeholder={'Optional'}
                value={proxyFormData?.password}
                onChange={(e) =>
                  setProxyFormData((data) => {
                    return { ...data, password: e?.target?.value };
                  })
                }
              />
            </div>
          </Space>
        </div>
      </Modal>
    </AuthLayout>
  );
};

export default Proxies;
