import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_URL } from '../../Config';
import { setCookie } from '../../utils/helper';
import { getAdmin } from './adminSlice';

const initialState = {
  isLoading: false,
  isError: null,
  errors: [],
  isSuccess: false,
  message: '',
};

export const adminLogin = createAsyncThunk(
  'admin/login',
  async ({ data, navigate, onSuccess, notification }, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.post(`${BASE_URL}/api/auth/admin/login`, data);
      let token = response?.data?.token;
      dispatch(getAdmin(token));
      setCookie('token', token);
      response?.data?.message && notification.success({ message: 'Success', description: response?.data?.message });
      onSuccess && onSuccess();
      navigate(`/`);
      return response?.data;
    } catch (error) {
      notification.error({
        message: 'Error',
        description: error?.response?.data?.errors[0]?.message || 'Network error',
      });
      return rejectWithValue(error?.response?.data);
    }
  }
);

const adminLoginSlice = createSlice({
  name: 'Admin Login',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(adminLogin.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(adminLogin.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isError = false;
      state.errors = [];
      state.isSuccess = action.payload?.success;
      state.message = action.payload?.message;
    });
    builder.addCase(adminLogin.rejected, (state, action) => {
      state.isLoading = false;
      state.errors = action.payload?.errors || [];
      state.isError = true;
    });
  },
});

export default adminLoginSlice?.reducer;
